<template>
  <c-base-modal>
    <div class="flex bg-white w-full h-full justify-center z-50 flex-col">
      <div class="flex text-gray-900 font-bold text-2xl justify-center">
        <c-icon name="letter-n" width="64" height="64" />
        <c-icon name="letter-o" width="64" height="64" />
        <c-icon name="letter-t" width="64" height="64" />
        <c-icon name="letter-f" width="64" height="64" />
        <c-icon name="letter-o" width="64" height="64" />
        <c-icon name="letter-u" width="64" height="64" />
        <c-icon name="letter-n" width="64" height="64" />
        <c-icon name="letter-d" width="64" height="64" />
      </div>
      <div class="flex justify-center">
        <c-btn @click="$router.back()">Back</c-btn>
      </div>
    </div>
  </c-base-modal>
</template>

<script>
export default {
  name: 'V404',
}
</script>
